import { Address } from 'viem';

import { ApiOptions } from '../helpers/api';
import { deserialize, JsonApiObject } from '../helpers/jsonapi';
import Api from './ApiProvider';
import {
  GetOneInchGasPricesResponse,
  GetOneInchQuoteResponse,
  GetOneInchTermsResponse,
  GetOneInchTokenAllowanceResponse,
  GetOneInchTxApproveResponse,
  GetTokenPriceInUSDResponse,
  MakeOneInchTokensSwapResponse,
  OneInchNetwork,
  OneInchToken,
  StoreSwapTxResponse,
} from './types';

export const getOneInchNetwork = async (options?: ApiOptions) => {
  const data = await Api.get('v2/integrations/1inch', options).json<JsonApiObject>();

  return deserialize<OneInchNetwork[]>(data);
};

export const getOneInchTokens = async (networkId: OneInchNetwork['id'], options?: ApiOptions) => {
  const data = await Api.get(
    `v2/integrations/1inch/${networkId}/tokens`,
    options,
  ).json<JsonApiObject>();

  return deserialize<OneInchToken[]>(data);
};

export const getOneInchQuote = async (
  options: ApiOptions<{
    searchParams: {
      fromTokenAddress: string;
      toTokenAddress: string;
      amount: string;
      networkId: OneInchNetwork['id'];
    };
  }>,
  signal?: AbortSignal,
) => {
  return Api.get(`v2/integrations/1inch/${options.searchParams.networkId}/quote`, {
    ...options,
    signal,
  }).json<GetOneInchQuoteResponse>();
};

export const getOneInchTokenAllowance = (
  networkId: OneInchNetwork['id'],
  options: ApiOptions<{
    searchParams: {
      tokenAddress: Address;
      walletAddress: Address;
    };
  }>,
) => {
  return Api.get(
    `v2/integrations/1inch/${networkId}/allowance`,
    options,
  ).json<GetOneInchTokenAllowanceResponse>();
};

export const getOneInchTxApprove = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    searchParams: {
      tokenAddress: string;
      amount: number;
    };
  }>;
}) => {
  return Api.get(
    `v2/integrations/1inch/${networkId}/approve/tx`,
    options,
  ).json<GetOneInchTxApproveResponse>();
};

export const makeOneInchTokensSwap = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    searchParams: {
      fromTokenAddress: string;
      toTokenAddress: string;
      amount: number;
      walletAddress: string;
      slippage: number;
    };
  }>;
}) => {
  return Api.get(`v2/integrations/1inch/${networkId}/swap`, options).json<
    | MakeOneInchTokensSwapResponse
    | {
        message: string;
        error: {
          message: string;
        };
      }
  >();
};

export const storeSwapTx = async ({
  networkId,
  options,
}: {
  networkId: OneInchNetwork['id'];
  options: ApiOptions<{
    json: {
      tx: string;
      operationId: string;
    };
  }>;
}) => {
  const data = await Api.post(
    `v2/integrations/1inch/${networkId}/swap/confirm`,
    options,
  ).json<JsonApiObject>();

  return deserialize<StoreSwapTxResponse>(data);
};

export const getTokenPriceInUSD = async (networkId: number, tokenAddress: Address) => {
  const data = await Api.get(
    `v2/integrations/1inch/${networkId}/tokens/${tokenAddress}`,
  ).json<JsonApiObject>();

  return deserialize<GetTokenPriceInUSDResponse>(data);
};

export const getOneInchGasPrices = async (networkId: number) => {
  const data = await Api.get(`v2/integrations/1inch/${networkId}/gas-price`).json<JsonApiObject>();

  return deserialize<GetOneInchGasPricesResponse>(data);
};

export const getOneInchTerms = async (options?: ApiOptions) => {
  return Api.get(
    'v2/integrations/1inch/terms-and-conditions',
    options,
  ).json<GetOneInchTermsResponse>();
};
