import { Options } from 'ky';

import Api, { ApiOptions } from './ApiProvider';
import { OpenableApp, SpaceApp, SpaceAppList } from './types';

export const getCurrentUserAppListInSpace = (options?: Options) =>
  Api.get('v2/store/space', options).json<SpaceAppList>();

export const transformSpaceAppToOpenableApp = ({
  id,
  app_id: appId,
  app_name: name,
  app_icon: icon,
  link: webVersionUrl,
  iframe_confirm: isWebVersionHasIframeSupport,
  is_adaptive: isWebVersionAdaptive,
  production_ready: isProductionReady,
  status: validationStatus,
  app_website: websiteUrl,
}: SpaceApp): OpenableApp => {
  return {
    id,
    appId,
    name,
    icon,
    validationStatus,
    isProductionReady,
    websiteUrl,
    isWebVersionAdaptive,
    isWebVersionHasIframeSupport,
    ...(webVersionUrl ? { webVersionUrl } : {}),
  };
};

export const getCurrentUserOpenableAppListInSpace = async (options?: Options) => {
  const { data } = await Api.get('v2/store/space', options).json<SpaceAppList>();

  return data.map(transformSpaceAppToOpenableApp);
};

export const removeCurrentUserAppFromSpace = (
  options: ApiOptions<{ searchParams: { app_id: number } }>,
) => Api.delete('v2/store/space/apps', options).json<{ delete: boolean }>();

export const addAppInCurrentUserSpace = (options: ApiOptions<{ json: { app_id: number } }>) =>
  Api.post('v2/store/space/apps', options).json<{ id: number } | { message: string }>();

export const getCurrentUserAppIdListInSpace = (options?: Options) =>
  Api.get('v2/store/space/apps', options).json<number[]>();
