import qs from 'qs';
import { Address } from 'viem';

import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import {
  DeserializedFreeMembershipTierActivationModel,
  DeserializedMembershipTierList,
  DeserializedMembershipTierUpdateModel,
  DeserializedMembershipTransitionList,
  GetSelfMembershipHistorySearchParams,
  MembershipDashboard,
  MembershipTierId,
} from './types';

export const getMembershipTiers = async (options?: ApiOptions) => {
  const data = await Api.get('v2/magicid/membership/rules', options).json<JsonApiObject>();

  return deserialize<DeserializedMembershipTierList>(data);
};

export const SELF_MEMBERSHIP_HISTORY_LIST_PAGE_LIMIT = 20;

export const getSelfMembershipHistory = (
  options?: ApiOptions<{ searchParams?: GetSelfMembershipHistorySearchParams }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      limit: SELF_MEMBERSHIP_HISTORY_LIST_PAGE_LIMIT,
      ...options?.searchParams,
      type: [options?.searchParams?.type],
    },
  };

  const searchParams = qs.stringify(optionsWithDefaults.searchParams);

  return Api.get('v2/magicid/user/membership/history', {
    ...optionsWithDefaults,
    searchParams,
  }).json<DeserializedMembershipTransitionList>();
};

export const getMembershipDashboard = async (options?: ApiOptions) => {
  return Api.get('v2/magicid/user/membership/dashboard', options).json<MembershipDashboard>();
};

export const getDepositWalletAddress = async (options?: ApiOptions) => {
  return Api.get('v2/magicid/user/membership/deposit/address', options).json<{
    address: Address;
  }>();
};

export const changeMembershipTier = async (
  options: ApiOptions<{
    json: {
      targetTier: MembershipTierId;
      custodial?: boolean;
    };
  }>,
) => {
  const data = await Api.post('v2/magicid/user/membership/change', options).json<JsonApiObject>();

  // Custodial case: options.json.custodial === true
  if (data && 'address' in data) {
    return data as unknown as { address: Address };
  }

  // Downgrade case: we MUST only pass lower tiers to make it work correct
  if (data && 'result' in data) {
    return data as unknown as { result: string };
  }

  // Enough Total Reward to get the Tier case
  if (data && 'type' in data && data.type === 'TIER') {
    return data as unknown as DeserializedFreeMembershipTierActivationModel;
  }

  return deserialize<DeserializedMembershipTierUpdateModel>(data);
};

export const getTierChangePrice = async (
  options: ApiOptions<{
    searchParams: {
      targetTier: MembershipTierId;
    };
  }>,
) => {
  return Api.get('v2/magicid/user/membership/change-price', options).json<{
    userId: number;
    currentTier: MembershipTierId;
    targetTier: MembershipTierId;
    amountToLock: number;
    amountToDeposit: number;
  }>();
};

export const getWithdrawData = async (
  options: ApiOptions<{
    json: {
      targetTier: MembershipTierId;
    };
  }>,
) => {
  return Api.post('v2/magicid/user/membership/withdraw-locked', options).json<{
    amountInWei: bigint;
    signature: Address;
    timestampLimit: number;
    timestampNow: number;
    to: Address;
    transactionId: string;
    userId: string;
  }>();
};

export const verifyDowngradeMembershipTier = (
  options?: ApiOptions<{
    json: {
      activationCode: string;
    };
  }>,
) => {
  return Api.post('v2/magicid/user/membership/activate', options).json<{
    data: {
      id: number;
      type: 'TIER';
      attributes: {
        key: MembershipTierId;
        name: string;
        description: string;
        amountToLock: number;
        rank: number;
      };
    };
  }>();
};

export const sendMembershipChangeVerificationCode = (options?: ApiOptions) => {
  return Api.post('v2/magicid/user/membership/send-code', options).json();
};

export const upgradeMembershipTierForFree = async (
  options: ApiOptions<{
    json: {
      message: string;
      signature: string;
    };
  }>,
) => {
  const data = await Api.post(
    'v2/magicid/user/membership/activate/free-plus',
    options,
  ).json<JsonApiObject>();

  return deserialize<DeserializedFreeMembershipTierActivationModel>(data);
};

export const confirmDowngrade = async () => {
  const data = await Api.post('v2/magicid/user/membership/downgrade/apply').json<JsonApiObject>();

  return deserialize<DeserializedFreeMembershipTierActivationModel>(data);
};
