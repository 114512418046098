import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';
import { WallCategoriesResponse, WallRequest, WallResponse } from './types/magicWall';

export const DEFAULT_LIMIT = 20;

export const getMagicWallList = async (options: ApiOptions<{ searchParams: WallRequest }>) => {
  const data = await Api.get('v2/hot-offers/magic-wall', options).json<JsonApiObject>();

  return deserialize<WallResponse>(data);
};

export const getMagicWallCategoriesList = () =>
  Api.get('v2/hot-offers/categories').json<WallCategoriesResponse>();
