import { Options } from 'ky';
import qs from 'qs';

import { CategoryApp, MyAppsParams, OldAppResponse } from '@api';

import Api from './ApiProvider';

// TODO: Ensure that is works fine after Spaces is done fetchFileBlob
export const fetchFileBlob = (url: string, Authorization: string) => {
  return Api.get(url, { headers: { Authorization } });
};

export const getCurrentUserApps = (options?: { searchParams?: MyAppsParams }) => {
  const searchParams = qs.stringify(options?.searchParams);

  return Api.get('v2/store/creator/apps', { searchParams }).json<OldAppResponse>();
};

export const fetchCategories = (options?: Options) =>
  Api.get('v2/store/meta/categories', options).json<CategoryApp[]>();
