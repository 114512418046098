import Api from './ApiProvider';

export interface UploadFileData {
  id: number;
  hash: string;
  name: string;
  size: number;
  type: string;
  access: string;
  userId: number;
  userIds: number[];
  resolutions: Record<string, string>;
}

export const uploadFile = (file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  return Api.post('v2/storage/upload', { body: formData }).json<UploadFileData>();
};
