export interface ProjectValidationChain {
  id: number;
  chain_name: string;
  chain_short_name?: string;
  icon?: string;
  contract_link?: string;
}

export interface ProjectValidation {
  id: string;
  ipfs: string;
  appId: number;
  type: string;
  start: string;
  end: string;
  finished: boolean;
  score: number;
  passed: boolean;
  title: string;
  body: string;
  choices: string[]; // ["Yes", "No"]
  snapshot: number;
  app_name: string;
  voter_count: number;
  voter_yes_count: number;
  isVoted: boolean;
  app_id: string;
  status: string;
  production_ready: boolean;
  icon: string;
  short_description: string;
  category_id: number;
  category_name: string;
  chains?: ProjectValidationChain[];
}

export interface UserDiscordRole {
  id: string;
  name: string;
  description: string | null;
  permissions: number;
  permissions_new: number;
  position: number;
  color: number;
  hoist: boolean;
  managed: boolean;
  mentionable: boolean;
  icon: string | null;
  unicode_emoji: string | null;
  tags: {
    premium_subscriber: string | null;
  };
  flags: number;
}

export interface ProjectValidationList {
  count: string;
  rows: ProjectValidation[];
}

export interface UserDiscordRolesList {
  count: string;
  rows: UserDiscordRole[];
}

export const PROPOSAL_FILTER = {
  ALL: 'ALL',
  ENDED: 'ENDED',
  ACTIVE: 'ACTIVE',
} as const;

export const PARTICIPATION_FILTER = {
  ALL: 'ALL',
  PARTICIPATED: 'PARTICIPATED',
  NOT_PARTICIPATED: 'NOT_PARTICIPATED',
} as const;

// TODO: find better place for it, because it can be also used in different endpoints
export const ORDER_BY = {
  DESK: 'DESK',
  ASK: 'ASK',
} as const;

export type ProposalFilterValue = (typeof PROPOSAL_FILTER)[keyof typeof PROPOSAL_FILTER];

export type ParticipationFilterValue =
  (typeof PARTICIPATION_FILTER)[keyof typeof PARTICIPATION_FILTER];

export type OrderByValue = (typeof PARTICIPATION_FILTER)[keyof typeof PARTICIPATION_FILTER];

export type ProjectValidationListSearchParams = {
  limit?: number;
  offset?: number;
  orderBy?: OrderByValue;
  participation?: ParticipationFilterValue;
  proposal?: ProposalFilterValue;
  whereLikeAppName?: string;
};

export type UserDiscordListSearchParams = {
  rolesIds?: string[];
};

export interface ProjectValidationListPageSearchParams {
  page?: string | number;
  status?: string;
  search?: string;
}

export const PROJECT_VALIDATION_STATUS_FILTER = {
  ALL: 'all',
  VOTED: 'voted',
  ENDED: 'ended',
  ACTIVE: 'active',
} as const;

export const isProjectValidationStatusFilterValue = (
  value: unknown,
): value is ProjectValidationStatusFilterValue => {
  return (
    typeof value === 'string' &&
    Object.values(PROJECT_VALIDATION_STATUS_FILTER).includes(value as any)
  );
};

export type ProjectValidationStatusFilterValue =
  (typeof PROJECT_VALIDATION_STATUS_FILTER)[keyof typeof PROJECT_VALIDATION_STATUS_FILTER];
