import { DeserializedData } from './app';

export const ACTION_TYPES = {
  LOGIN: 'LOGIN',
  GO_TO: 'GO_TO',
  GO_TO_AUTH: 'GO_TO_AUTH',
  APP: 'APP',
  SOQUEST: 'SOQUEST',
  HTML: 'HTML',
} as const;

export const STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
} as const;

export interface StoryBlock {
  bannerUrl?: string;
  title?: string;
  description?: string;
  bannerBottomUrl?: string;
  action?: {
    type: 'GO_TO' | 'GO_TO_AUTH' | 'LOGIN';
    buttonText: string;
    internalRoute?: string;
    externalRoute?: string;
  };
}

export interface ZealyQuestCategory {
  id: string;
  name: string;
  hue: number;
}

export interface ZealyQuest {
  id: string;
  name: string;
  description: string;
  categoryId: string;
  url: string;
  xp: number;
  frequency: string;
}

export interface StoryMetadataPreview {
  url: string;
  width?: string;
  height?: string;
  type?: string;
}

export const STORY_TYPE = {
  ZEALY: 'ZEALY',
  ZEALY_LEADERBOARD: 'ZEALY_LEADERBOARD',
  CUSTOM: 'CUSTOM',
  STORM_TRADE: 'STORM_TRADE',
} as const;

type StoryTypeName = keyof typeof STORY_TYPE;
type StoryType = (typeof STORY_TYPE)[StoryTypeName];

export type StoryData<Type extends StoryType, Data = undefined> = {
  id: string;
  type: Type;
  status: keyof typeof STATUS_TYPES;
  metadata?: {
    title: string;
    description: string;
    keywords?: string;
    preview?: StoryMetadataPreview;
  };
} & (Data extends undefined ? unknown : { data: Data });

export type ZealyStoryData = StoryData<typeof STORY_TYPE.ZEALY>;

export type ZealyLeaderboardStoryData = StoryData<typeof STORY_TYPE.ZEALY_LEADERBOARD>;

export type StormTradeLeaderboardData = StoryData<typeof STORY_TYPE.STORM_TRADE>;

export type ZealyStoryTaskParticipant = DeserializedData<
  'ZEALY_PARTICIPANT',
  {
    icon: string | null;
    name: string;
    questsCompleted: number;
    address: string;
    reward: number | string;
    xp: number;
  }
>;

export type ZealyStoryLeaderboardMeta = DeserializedData<
  'ZEALY_LEADERBOARD',
  {
    reward: string;
    totalPages: number;
    totalParticipants: number;
  }
>;

export type ZealyStoryLeaderboard = {
  data: (ZealyStoryTaskParticipant | ZealyStoryLeaderboardMeta)[];
};

export type DeserializedZealyTask = DeserializedData<
  'ZEALY_TASK',
  {
    categoryId: string;
    name: string;
    description: string;
    frequency: 'ONE-TIME' | 'MULTIPLE' | 'DAILY';
    url: string;
    xp: number;
    completed: boolean;
    count: number;
  }
>;

export type DeserializedZealyCategory = DeserializedData<
  'ZEALY_CATEGORY',
  {
    name: string;
    color: string | null;
  }
> & {
  id: string;
};

export type DeserializedZealyRef = DeserializedData<
  'ZEALY_REF',
  {
    zealyId: string;
    address: string;
    // NOTE: if `selfZealyAccount.account.attributes.rank` could be null
    // in case if we cant determine his position because he doesn't have xp (probably because haven't done any task)
    rank: number | null;
    reward: number | null;
    icon?: string | null;
    xp: number;
  }
>;

export type DeserializedZealyCampaign = DeserializedData<
  'ZEALY_CAMPAIGN',
  {
    end: string;
    invite: string;
    link: string;
    name: string;
    start: null | string;
  }
>;

export interface ZealyTaskList {
  data: (
    | DeserializedZealyTask
    | DeserializedZealyCategory
    | DeserializedZealyRef
    | DeserializedZealyCampaign
  )[];
}

export interface NormalizedZealyCampaign {
  quests: DeserializedZealyTask[];
  categories: DeserializedZealyCategory[];
  account: DeserializedZealyRef | null;
  campaign: DeserializedZealyCampaign | null;
}

export interface CommonStory {
  id: string;
  type: 'CLASSIC' | 'SOQUEST';
  status: keyof typeof STATUS_TYPES;
  name: string;
  index: number;
  content: {
    metadata?: {
      title: string;
      description: string;
      keywords?: string;
    };
    appId?: string;
    layout?: string; // TODO: Ensure musthaveness of this field or remove from 'Story' interface
    authRequired?: boolean;
    bannerDeskUrl?: string;
    bannerLargeTabletUrl?: string;
    bannerMobileUrl?: string;
    bannerSmallTabletUrl?: string;
    bannerUrl?: string;
    bigText?: string;
    buttonText?: string;
    customHtml?: string;
    description?: string;
    disabled?: boolean;
    externalRoute?: string;
    html?: string;
    hardcodedHtml?: string;
    internalRoute?: string;
    storyBlocks?: StoryBlock[];
    title?: string;
    type: keyof typeof ACTION_TYPES;
    config?: {
      key: string;
      scode?: string; // TODO: Ensure musthaveness of this field or remove from 'Story' interface
      ccode: string;
      leaderboardId?: string;
    };
  };
}

export type Story =
  | CommonStory
  | ZealyStoryData
  | ZealyLeaderboardStoryData
  | StormTradeLeaderboardData;

export interface CampaignParticipant {
  id: number;
  type: 'ZEALY_PARTICIPANT';
  attributes: {
    name: string | null;
    icon: string | null;
    // evm wallet
    address: string;
    // ton wallet
    addressExtra?: string;
    xp: number;
    position: number;
  };
}

export interface CampaignNonParticipant {
  id: null;
  type: 'ZEALY_PARTICIPANT';
  attributes: {
    name: null;
    icon: null;
    // evm wallet
    address: null;
    // ton wallet
    addressExtra?: null;
    xp: null;
    position: null;
  };
}

export type StormCampaignParticipant = CampaignParticipant | CampaignNonParticipant;

export interface CampaignParticipantList {
  data: CampaignParticipant[];
  meta: {
    campaign: string;
  };
}

export interface CampaignLeaderboardItem {
  id: string;
  type: 'CAMPAIGN_PARTICIPANT';
  attributes: {
    points: number;
    rank: number;
    evmWallet?: string;
    tonWallet?: string;
  };
}

// remove type if backend returns the same type field as CampaignLeaderboardItem
export interface SelfCampaignLeaderboardMe {
  id: string;
  type: 'CAMPAIGN_PARTICIPANT';
  attributes: {
    points: number;
    rank: number;
    evmWallet?: string;
    tonWallet?: string;
    email?: string;
    telegramId?: string;
  };
}
