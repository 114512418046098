import { MembershipTierId } from './membership';
import { DeserializedPublicUser, KarmaPublicUser } from './user';

export interface ReferralStats {
  id: number;
  type: string;
  attributes: {
    invited: number;
    joined: number;
    karmaEarned: number;
    extraKarmaEarned?: number;
    emailsSent: number;
    emailsDailyLimit: number;
    referralId: string;
    karma: number;
    isKOL: boolean;
    sqrEarned: number;
  };
}

export interface UserKarma {
  /**
   * will contain reward that user is currently withdrawing (because transactions on web3 can take some time)
   */
  blockedRewards?: number;
  currency: string;
  dailyKarma: number;
  dailyReward: number;
  extraDailyReward: number;
  overallKarma: number;
  position: number;
  totalSqrp: number;
  totalKarma: number;
  rewardsBlockingTime?: number;
  share: number;
  totalReward: number;
  user: KarmaPublicUser;
  userId?: number;
  usdt: number;
  usdc: number;
}

export const normalizeUserKarma = (data?: Partial<UserKarma> | null): UserKarma => {
  return {
    user: data?.user ?? ({} as KarmaPublicUser),
    position: data?.position ?? 0,
    currency: data?.currency ?? '',
    overallKarma: data?.overallKarma ?? 0,
    totalSqrp: data?.totalSqrp ?? 0,
    share: data?.share ?? 0,
    extraDailyReward: data?.extraDailyReward ?? 0,
    dailyReward: data?.dailyReward ?? 0,
    totalReward: data?.totalReward ?? 0,
    totalKarma: data?.totalKarma ?? 0,
    dailyKarma: data?.dailyKarma ?? -1,
    usdt: data?.usdt ?? 0,
    usdc: data?.usdc ?? 0,
  };
};

export interface LeaderboardKarma {
  rows: UserKarma[];
  totalKarma: number;
  totalUsers: number;
}

export interface KarmaRewardSettings {
  currency: string;
  daily_pool_amount: number;
  key: string;
  updated_at: string;
  withdrawal_limit: number;
}

export const normalizeKarmaRewardsSettings = ({
  currency,
  daily_pool_amount,
  key,
  updated_at,
  withdrawal_limit,
}: Partial<KarmaRewardSettings> = {}): KarmaRewardSettings => {
  return {
    currency: currency ?? '',
    daily_pool_amount: daily_pool_amount ?? 0,
    key: key ?? '',
    updated_at: updated_at ?? '',
    withdrawal_limit: withdrawal_limit ?? 0,
  };
};

export interface KarmaHistory {
  currency: 'USDT' | 'SQR' | 'SQRp';
  dailyKarma: number;
  dailyReward: number;
  extraDailyReward?: number;
  dailyRewardSqrp?: number;
  dailyRewardSqrpExtra?: number;
  date: string;
  karma: number;
  share: number;
  totalReward: number;
  userId: number;
}

export interface KarmaHistoryList {
  rows: KarmaHistory[];
  count: number;
}

export const KARMA_ACTION = {
  CLAIM_HOT_OFFER: 'CLAIM_HOT_OFFER',
  CONNECT_SOCIAL: 'CONNECT_SOCIAL',
  MAGIC_BOOST_BANKING: 'MAGIC_BOOST_BANKING',
  REFERRAL: 'REFERRAL',
  REFERRER: 'REFERRER',
  SHARE_APP: 'SHARE_APP',
  STORE_LOGIN: 'STORE_LOGIN',
  VALIDATION: 'VALIDATION',
} as const;

export type KarmaActionName = keyof typeof KARMA_ACTION;

export type KarmaAction = (typeof KARMA_ACTION)[KarmaActionName];

export interface CurrentKarmaAction {
  CLAIM_HOT_OFFER: number;
  CONNECT_SOCIAL: number;
  MAGIC_BOOST_BANKING: number;
  REFERRAL: number;
  REFERRER: number;
  SHARE_APP: number;
  STORE_LOGIN: number;
  VALIDATION: number;
  [key: string]: number;
}

export interface RewardGuides {
  data: {
    id: string;
    type: string;
    attributes: {
      description: null | string;
      frequency: string;
      name: string;
      points: number;
      regularity: null | string;
      status: string;
    };
  }[];
}

export interface RewardGuidesWithKarmaEarnedPoints {
  id: string;
  type: string;
  attributes: {
    description: null | string;
    frequency: string;
    name: string;
    points: number;
    regularity: null | string;
    status: string;
    earnedPoints: number;
  };
}

export interface KarmaActionsByDate {
  count: number;
  rows: KarmaActionByDay[];
}

export interface KarmaActionByDay {
  action_name: string;
  created: string;
  points: number;
  extra_points: number;
  membership_tier: MembershipTierId;
  timestamp: number;
  type: string;
  userId: number;
}

export type KarmaPeriodType = 'all' | 'daily';

export const INVITED_USER_STATUS = {
  JOINED: 'Joined',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
} as const;

export type InvitedUserStatusName = keyof typeof INVITED_USER_STATUS;

export type InvitedUserStatus = (typeof INVITED_USER_STATUS)[InvitedUserStatusName];

interface InvitedUserAttributes {
  status: InvitedUserStatus;
  updatedAt: string;
}

interface UserData {
  id: number;
  type: string;
}

export interface InvitedUserData extends UserData {
  attributes: InvitedUserAttributes;
  user: DeserializedPublicUser;
}
