import { HotOffer } from './hotOffers';
import { DeserializedUser } from './user';

export const DEVELOPER_CAMPAIGN_TAB_ID = {
  PENDING: 'PENDING',
  HISTORY: 'HISTORY',
} as const;

export type DeveloperCampaignTabId =
  (typeof DEVELOPER_CAMPAIGN_TAB_ID)[keyof typeof DEVELOPER_CAMPAIGN_TAB_ID];

export const DEVELOPER_CAMPAIGN_SORT_ACTION_TYPE = {
  asc: 'asc',
  desc: 'desc',
} as const;

export const DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE = {
  userId: 'userId',
  displayedName: 'displayedName',
  magicId: 'magicId',
} as const;

export type DeveloperCampaignSortActionType =
  (typeof DEVELOPER_CAMPAIGN_SORT_ACTION_TYPE)[keyof typeof DEVELOPER_CAMPAIGN_SORT_ACTION_TYPE];

export type DeveloperCampaignExtraFiltersActionType =
  (typeof DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE)[keyof typeof DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE];

export const DEVELOPER_CAMPAIGN_SORT_ACTION_NAME: Record<DeveloperCampaignSortActionType, string> =
  {
    [DEVELOPER_CAMPAIGN_SORT_ACTION_TYPE.desc]: 'Created: newest to oldest',
    [DEVELOPER_CAMPAIGN_SORT_ACTION_TYPE.asc]: 'Created: oldest to newest',
  };

export const DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_NAME: Record<
  DeveloperCampaignExtraFiltersActionType,
  string
> = {
  [DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE.magicId]: 'Magic ID',
  [DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE.displayedName]: 'Displayed Name',
  [DEVELOPER_CAMPAIGN_EXTRA_FILTERS_ACTION_TYPE.userId]: 'User ID',
};

export type DeveloperProfileSocialName =
  | 'github'
  | 'reddit'
  | 'discord'
  | 'x'
  | 'facebook'
  | 'telegram'
  | 'instagram';

export type Status = 'PENDING' | 'APPROVED' | 'REJECTED';

export interface CreateDeveloperProfileData {
  name: string;
  email: string;
  email_confirmed: null | boolean; // null when didn't try to confirm
  url: string;
  country: string;
  icon?: string;
  address?: string;
  privacy_policy?: string;
  team_size?: number;
  social_link?: {
    [key in DeveloperProfileSocialName]?: string;
  };
  meta_description?: string;
  meta_keywords?: string[];
}

export interface DeveloperProfile extends CreateDeveloperProfileData {
  creator_id: number;
  registration_time: string;
  total_downloads?: number;
  avg_rating?: string;
  address?: string;
  telegram_nickname: string;
  developer_name_id: string;
  developer_id: string;
}

export type UpdateDeveloperProfileData = Partial<CreateDeveloperProfileData>;

export interface DeveloperTeamMember {
  // NOTE: backend is not returning it yet
  icon?: string;
  displayedName: string | null;
  member_id: number;
  name: string;
  socials: string[];
  user_id: number | null;
}

export interface DeveloperTeamMemberList {
  all: string;
  data: DeveloperTeamMember[];
}

export interface DeveloperLiteApp {
  id: number;
  app_name: string;
  app_icon: string;
  app_id: string;
  category_id: number;
  category_name: string;
  subcategory_id: number;
  subcategory_name: string;
  description: string | null;
}

export interface DeveloperFullApp {
  id: number;
  app_id: string;
  app_name: string;
  app_icon: string;
  is_adaptive: boolean;
  is_verified: boolean;
  iframe_confirm: boolean;
  is_free: boolean;
  price: string | null;
  likes: number;
  downloads: number;
  link: string;
  index_hash: string | null;
  status: string;
  release_date: string;
  extended_ratings: string;
  user_id: number;
  validators_score: string;
  next_validate_date: string;
  user_icon: string;
  karma: string;
  user_name: string;
  category_id: number;
  category_name: string;
  subcategory_id: number;
  subcategory_name: string;
  cmc_id: number | null;
  token_symbol: string | null;
  token_name: string | null;
  app_banner: string | null;
  app_chain: string | null;
  token_price_change: string | null;
  token_price: string | null;
  creator_id: number;
  developer_name_id: string;
  ratings: string | null;
  description: string | null;
  votingPublicKey: string | null;
}

export interface CurrentDeveloperAppList {
  data: DeveloperFullApp[];
  total_apps: string;
}

export interface DeveloperAppList {
  count: string;
  rows: DeveloperLiteApp[];
}

export interface DeveloperToken {
  app_icon: string;
  app_name: string;
  token_name: string;
  token_symbol: string;
  symbol?: string;
  fullname?: string;
  cmc_id?: number;
  chain?: string;
  price?: number;
  marketCap?: number;
  volume24h?: number;
  hourChange?: number;
  dayChange?: number;
  weekChange?: number;
}

export type CampainListParams = {
  status: DeveloperCampaignTabId;
  order: DeveloperCampaignSortActionType | boolean;
  limit?: number;
  offset?: number;
  offerId?: string | boolean;
  search?: string;
  magicId?: string;
  displayedName?: string;
  userId?: string;
};

export type CampainAttributes = {
  image: string;
  karma: number;
  userName: string;
  wallet: string;
  claimedAt: string;
  campaignName: string;
  status: Status;
  files: string[];
  text: string;
  link: string;
  rejectReason: string;
};

export interface CampainList {
  id: number;
  user: DeserializedUser;
  hotOffer: HotOffer;
  attributes: CampainAttributes;
}

export interface CampainResponse {
  id: number;
  rows: CampainList[];
  totalCount: number;
  isChecked: boolean;
}

export type ReviewListStatus = 'approve' | 'decline';

export interface CampainReviewListRequest {
  ids: number[];
  status?: ReviewListStatus;
  reason?: string;
}

export interface CampainReviewFiltersList {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface CampainReviewListResponse {
  rows: Array<unknown>;
  totalCount: number;
}

export interface CampainReviewApproveRequest {
  offerId?: string;
  comment: string;
}
